<template>
  <section class="page-section" id="about">
    <div class="container">
      <!-- 概要 Section Heading-->
      <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">アンソロ概要</h2>

      <!-- Icon Divider-->
      <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.5 47.5" class="fab">
              <!-- Twitter Emoji - https://github.com/twitter/twemoji/tree/gh-pages -->
              <defs id="defs6"><clipPath id="clipPath16" clipPathUnits="userSpaceOnUse"><path id="path18" d="M 0,38 38,38 38,0 0,0 0,38 Z"/></clipPath></defs><g transform="matrix(1.25,0,0,-1.25,0,47.5)" id="g10"><g id="g12"><g clip-path="url(#clipPath16)" id="g14"><g transform="translate(3.9995,17.5)" id="g20"><path id="path22" style="fill:#f4900c;fill-opacity:1;fill-rule:nonzero;stroke:none" d="M 0,0 C 0,9.112 7.387,16.5 16.5,16.5 25.613,16.5 33,9.112 33,0 33,-9.113 25.613,-16.5 16.5,-16.5 7.387,-16.5 0,-9.113 0,0"/></g><g transform="translate(12.4136,29.4146)" id="g24"><path id="path26" style="fill:#662113;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 c -0.267,0.267 -0.797,0.197 -1.355,-0.12 -3.3,2.732 -8.653,3.652 -8.895,3.692 -0.546,0.09 -1.059,-0.277 -1.15,-0.821 -0.091,-0.544 0.276,-1.06 0.821,-1.151 0.053,-0.01 4.933,-0.854 7.821,-3.16 -0.275,-0.525 -0.324,-1.015 -0.07,-1.268 0.39,-0.391 1.34,-0.074 2.121,0.707 C 0.074,-1.34 0.391,-0.39 0,0"/></g><g transform="translate(22,36.0005)" id="g28"><path id="path30" style="fill:#5c913b;fill-opacity:1;fill-rule:nonzero;stroke:none" d="M 0,0 C 0,0 -3.106,-4.318 -7.021,-5.273 -10,-6 -13.959,-6.07 -14.354,-5.151 c -0.394,0.919 1.572,3.937 4.969,5.393 C -5.988,1.698 0,0 0,0"/></g></g></g></g>
            </svg>
          </div>
          <div class="divider-custom-line"></div>
      </div>

      <!-- 概要 Section Content-->
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <p class="col-lg-10 mx-auto lead">
            『ARIA The CREPUSCOLO』の公開をささやかながらお祝いしたく立ち上げた、オレぷらの<span style="text-decoration: line-through">オレぷらによる</span>オレぷらのための非公式アンソロジーです。<br />
            様々な絵師の方々のオレンジぷらねっとの妄想や好きなところなどの思いを詰め込んだ1冊となっています！
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentAbout'
}
</script>
